import React from "react";
import Hero from "assets/carrier/remotecustomerhero.webp";
import { Link } from "gatsby";

import Priority from "assets/checked.svg";
import Priority1 from "assets/carrier/priority1.svg";
import { prototype } from "react-star-rating-component";
import QualityGurantee from "components/YearGuarantee/quality-gurantee";
import Layout from "components/layout";
import SEO from "components/seo";
import CalendarBlank from "assets/CalendarBlank.svg";
import moment from "moment";

const RemoteCustomer = (props) => {
  const pageData = props.pageContext.node.data;
  
  const backgroundImageStyle = {
    backgroundImage: `url(${Hero})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const listpagedata = pageData.side_section.list;

  const listdate = pageData.created_at;

  const sidebarsectiontext = pageData.side_section.description;

  const textdata = pageData.content;

  const { meta_title, meta_description, meta_keywords } = pageData;
  return (
    <Layout>
      <SEO
        title={meta_title}
        description={meta_description}
        keywords={meta_keywords}
        slug={props?.path}
      />
      <div>
        <section
          className="px-4  py-12 lg:h-[200px] flex items-center"
          style={backgroundImageStyle}
        >
          {/* <QualityGurantee
            heading="Remote Customer Success Specialist "
            subheading=""
          /> */}
          <div className="pt-5 sm:px-6 py-6  max-w-6xl mx-auto">
            <div className="flex flex-col items-center justify-center gap-8 text-[#ffffff]">
              <h1 className="font-bold text-4xl md:text-[50px] leading-[62px] text-white font-primary text-center">
                {pageData.title}
              </h1>
            </div>
          </div>
        </section>
        <div className="w-11/12 max-w-7xl mx-auto flex flex-col">
          <div className="hidden lg:block w-full px-4 2xl:px-0 py-7 ">
            <div className="flex max-w-7xl mx-auto gap-3 items-center text-base text-[#494949] font-opensans font-semibold">
              <Link to="/">
                <img
                  src="https://tomedes.gumlet.io/S3UploadedFiles/1666089711home.svg"
                  alt="home"
                />
              </Link>
              <img
                src="https://tomedes.gumlet.io/S3UploadedFiles/1666089692greater-than.svg"
                alt="navigate"
              />
              <Link to="/careers">
                <span className="text-[#d9d1d0]">Careers</span>
              </Link>
              <img
                src="https://tomedes.gumlet.io/S3UploadedFiles/1666089692greater-than.svg"
                alt="navigate"
              />
              <Link to="/careers/translation-jobs">
                <span className="text-[#d9d1d0]">Translation Jobs</span>
              </Link>
              <img
                src="https://tomedes.gumlet.io/S3UploadedFiles/1666089692greater-than.svg"
                alt="navigate"
              />
              <span className="">{pageData.title}</span>
            </div>
          </div>
        </div>
        <div className=" px-4 pt-5 sm:p-6  pb-8">
          <div className="max-w-6xl relative  mx-auto py-12 lg:flex justify-between gap-8 ">
            <div className="lg:w-2/3  lg:pl-16 lg:hidden block ">
              <div className="border border-[#E7F0FA] py-5 rounded-lg flex flex-col items-center justify-center ">
                <p className="font-normal text-base leading-6 text-[#000000]">
                  Date Posted:
                </p>
                <div className="mt-[10px] flex gap-3">
                  <img src={CalendarBlank} alt="calender" />
                  <h3 className="text-[28px] font-normal leaidng-6 text-[#141414]">
                    {moment(listdate).format("MMMM D, YYYY")}
                  </h3>
                </div>
              </div>
              <div className="bg-[#F5F9FF] rounded-lg px-8 py-8 lg:w-[320px] mt-[10px]">
                <div className=" grid md:grid-cols-3 gap-4 lg:grid-cols-1">
                  {listpagedata.map((item, i) => (
                    <div className="flex md:flex-col lg:flex  gap-4 py-4">
                      <div>
                        <img
                          src={Priority}
                          alt=""
                          className="lg:block hidden w-max"
                        />
                      </div>
                      <div className="flex items-starts md:items-center md:justify-center">
                        <img
                          src={Priority1}
                          alt=""
                          className="lg:hidden block "
                        />
                      </div>
                      <div className=" ">
                        <h2 className="text-[#00173A] font-semibold text-starts md:text-center lg:text-starts mt-0 md:mt-4 lg:mt-0  text-lg leading-8 font-opensans">
                          {item.subtitle}
                        </h2>
                        <p className="text-[#00173A] font-normal text-starts md:text-center lg:text-starts  text-lg leading-8 font-opensans">
                          {item.title}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                <hr className="mt-6 flex-1 border-t border-[#649DF2]  " />
                <p
                  className="text-[#00173A] mt-4 font-normal text-lg leading-8 font-opensans"
                  dangerouslySetInnerHTML={{ __html: sidebarsectiontext }}
                ></p>
              </div>
            </div>
            <div className="lg:w-2/3 order-2 lg:order-1 mt-12 md:mt-0">
              <div>
                <h1 className="text-[#0A2641] text-3xl md:text-[40px] font-primary font-semibold leading-[60px]">
                  Job Overview
                </h1>

                <div
                  className="text-[#00173A] py-[40px] font-normal text-base md:text-lg leading-7 font-opensans text_style"
                  dangerouslySetInnerHTML={{
                    __html: textdata,
                  }}
                />
              </div>
            </div>
            <div className="lg:w-1/3 relative      order-1 lg:order-2 lg:block hidden ">
              <div className="sticky top-40">
                <div className="border border-[#E7F0FA] py-5 ml-6 rounded-lg flex flex-col items-center justify-center lg:w-[320px] ">
                  <p className="font-normal text-base leading-6 text-[#000000]">
                    Date Posted:
                  </p>
                  <div className="mt-[10px] flex gap-3">
                    <img src={CalendarBlank} alt="calender" />

                    <h3 className="text-[28px] font-normal leaidng-6 text-[#141414]">
                      {moment(listdate).format("MMMM D, YYYY")}
                    </h3>
                  </div>
                </div>
                <div className="bg-[#F5F9FF] ml-6 mt-[10px]   rounded-lg px-8 py-8 lg:w-[320px]">
                  {listpagedata.map((item, i) => (
                    <div className="flex  gap-5  pb-[40px] ">
                      <img src={Priority} alt="" className="w-max" />

                      <div className=" ">
                        <h2 className="text-[#00173A] font-semibold text-start md:text-center lg:text-start mt-0 md:mt-4 lg:mt-0  text-lg leading-8 font-opensans">
                          {item.title}
                        </h2>
                        <p className="text-[#00173A] font-normal text-start md:text-left lg:text-start  text-lg leading-8 font-opensans">
                          {item.subtitle}{" "}
                        </p>
                      </div>
                    </div>
                  ))}

                  <hr className="mt-6 flex-1 border-t border-[#649DF2]  " />

                  <p
                    className="text-[#00173A] mt-4 font-normal text-lg leading-8 font-opensans"
                    dangerouslySetInnerHTML={{ __html: sidebarsectiontext }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default RemoteCustomer;
